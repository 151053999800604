import { useMenuContext } from 'core/context/MenuProvider'
import useI18n from 'core/hooks/useI18n'

import viewports from 'components/AdamTheme/theme/viewports'
import Media from 'components/DatoPage/blocks/components/Media'
import SubscribeAndFollow from 'components/SubscribeAndFollow'

export default function Subscribe(): JSX.Element {
  const { t } = useI18n()
  const menuData = useMenuContext()

  return (
    <div className="relative mx-auto my-0 flex flex-col bg-cotton text-sm md:h-[400px] md:max-w-[calc(1724px-24px*2)] md:flex-row">
      <Media
        {...menuData.footerMedium}
        controls={false}
        sizes={`(min-width: ${viewports.md}px) 50vw, 100vw`}
        className="aspect-[1.5/1] md:!w-1/2"
      />
      <SubscribeAndFollow
        title={t('subscribe.title')}
        description={t('subscribe.description')}
        image={'/icons/pool.svg'}
        trackingCategory="footer"
        className="md:!w-1/2"
      />
    </div>
  )
}
