import { CartLine } from '@shopify/hydrogen-react/storefront-api-types'

import { useCart } from 'core/context/ShopifyProvider'
import useCheckoutUpdateLinesMutation from 'core/hooks/useCheckoutUpdateLinesMutation'
import useIsWholesale from 'core/hooks/useIsWholesale'
import useSession from 'core/hooks/useSession'
import mergeClasses from 'core/utils/mergeClasses'
import removeTaxIfApplicable from 'core/utils/removeTaxIfApplicable'

// import { getCurrencySymbol } from 'core/utils/price'
// import { useCheckoutRemovePromoCodeMutation } from 'views/Checkout/_queries/checkout.generated'

import Price from 'components/Price'

import FreeShipping from './FreeShipping'
import LineList from './LineList'
import ProductList from './ProductList'

export default function CartSummary(): JSX.Element | null {
  const isWholesale = useIsWholesale()
  const { channel } = useSession()
  const { lines, cost } = useCart()

  const [updateLines] = useCheckoutUpdateLinesMutation()
  // const terms = useTermsFromShippingMethods()
  const priceLineClasses = 'flex items-center justify-between h-xl'

  // const { currentShippingMethod, isFreeShipping, amountTillFreeShipping, percentileOfFreeShipping } =
  //   useShippingCarrier({
  //     subTotal: checkout?.subtotalPrice?.gross.amount,
  //     total: checkout?.totalPrice?.gross.amount,
  //     hasVoucher: !!checkout?.voucherCode,
  //   })

  // const totalGross = checkout?.totalPrice?.gross.amount || 0
  // const shippingAmount = currentShippingMethod?.price.amount || 0

  // const estimatedTotalWithDelivery = checkout?.deliveryMethod
  //   ? checkout?.totalPrice.gross
  //   : {
  //       ...checkout?.totalPrice?.gross,
  //       ...{ amount: totalGross /* + shippingAmount */ },
  //     }

  // const total = estimatedTotalWithDelivery.amount
  // const subTotal = displayGross ? checkout?.subtotalPrice.gross.amount : checkout?.subtotalPrice?.net.amount

  // const [removePromoCode] = useCheckoutRemovePromoCodeMutation({
  //   variables: {
  //     checkoutId: checkout?.id || '',
  //     promoCode: checkout?.voucherCode || '',
  //   },
  // })

  // if the prop changes it needs to alter the state
  // useEffect(() => {
  //   setFolded(foldedProducts)
  // }, [foldedProducts])

  const List = isWholesale ? ProductList : LineList

  return (
    <>
      <List
        lines={lines as CartLine[]}
        onChange={updateLines}
      />
      <div className={mergeClasses('w-full mb-sm', 'px-md py-0')}>
        {false && (
          <FreeShipping
            contrast={false}
            percentileOfFreeShipping={0 || 0}
            currency={'EUR'}
            amountTillFreeShipping={0}
          />
        )}

        {!isNaN(Number(cost?.totalTaxAmount?.amount)) && (
          <div className="m-0 mt-sm">
            <div className={mergeClasses(priceLineClasses, 'text-xl font-bold')}>
              {`Tax`}
              <Price
                className="text-xl font-bold"
                currencyCode={cost?.totalTaxAmount?.currencyCode as string}
                price={Number(cost?.totalTaxAmount?.amount)}
              />
            </div>
          </div>
        )}
        {!isNaN(Number(cost?.subtotalAmount?.amount)) && (
          <div className="m-0 mt-sm">
            <div className={mergeClasses(priceLineClasses, 'text-xl font-bold')}>
              {`Subtotal`}
              <Price
                className="text-xl font-bold"
                currencyCode={cost?.subtotalAmount?.currencyCode as string}
                price={removeTaxIfApplicable(channel, Number(cost?.subtotalAmount?.amount))}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
