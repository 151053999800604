import debounce from 'lodash/debounce'
import { useEffect } from 'react'

export default function useOnWindowResize(callback: () => void): void {
  useEffect(() => {
    const debouncedCallback = debounce(callback, 100)
    window.addEventListener('resize', debouncedCallback)

    if (typeof window.screen != 'undefined' && typeof window.screen.orientation != 'undefined') {
      window.screen.orientation.addEventListener('change', debouncedCallback, false)
    }

    return () => {
      window.removeEventListener('resize', debouncedCallback)
      if (typeof window.screen != 'undefined' && typeof window.screen.orientation != 'undefined') {
        window.screen.orientation.removeEventListener('change', debouncedCallback)
      }
    }
  }, [])
}
