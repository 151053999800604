import { Field, FormikProps, useFormikContext } from 'formik'
import { FocusEvent } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

import FormikErrorMessage from 'components/FormikErrorMessage'

type Field = {
  name: string
  value: string | boolean
  onChange: () => void
  onBlur?: () => void
  hidden?: boolean
}

type Form = {
  touched: Record<string, string>
  errors: Record<string, string>
}

type FormikAdapterType = {
  field: Field
  form: Form
  FieldComponent: React.FC<{ onFocus: (arg0: FocusEvent<HTMLInputElement>) => void }>
  noMargin: boolean
  description?: string
  forceValidate?: boolean
  onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void
  className?: string
}

function FormikAdapter({
  field,
  field: { name },
  FieldComponent,
  noMargin,
  description,
  forceValidate,
  className,
  ...props
}: FormikAdapterType): JSX.Element {
  const { setFieldTouched, isSubmitting, errors, touched }: FormikProps<any> = useFormikContext()
  const isTouched = !!touched[name]
  const hasErrors = !!errors[name]
  const isInvalid = (forceValidate || isTouched) && hasErrors
  const disabled = isSubmitting
  const onFocusPass = props.onFocus

  const inputProps = {
    disabled,
    ...field,
    ...props,
    isInvalid,
  }

  return (
    <div className={mergeClasses('relative w-full ', noMargin ? 'mb-0 pb-0' : 'mb-xs pb-md', className)}>
      <FieldComponent
        {...inputProps}
        onFocus={(event: FocusEvent<HTMLInputElement, Element>): void => {
          isTouched && setFieldTouched(name, false, true)
          onFocusPass && onFocusPass(event)
        }}
      />
      <FormikErrorMessage
        name={name}
        touched={forceValidate}
      />
      {description && <div className="mt-sm">{description}</div>}
    </div>
  )
}

type FormikField = {
  component: React.FC<any>
  [x: string]: any
}

const FormikField: React.FC<FormikField> = ({ component, ...props }) => {
  return (
    <Field
      component={FormikAdapter}
      FieldComponent={component}
      {...props}
    />
  )
}

export default FormikField
