import { useState, useEffect, HTMLAttributes } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'
import Input from 'components/Input'

const MAX = 45

function Divider({ vertical, ...props }: { vertical?: boolean } & HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={mergeClasses(
        'flex relative justify-around items-center border-b border-solid border-pillow p-sm last:border-b-0',
        vertical && 'h-[80px] p-0 flex-col justify-center',
        '![button]:w-full ![button]:h-[35%] ![&>div]:h-[30%] ![&>div]:shadow-none '
      )}
      {...props}
    />
  )
}

export default function SizeSelector({
  label,
  selected,
  available,
  onChange,
  disabled,
  noEdge,
}: {
  label: string
  selected: number
  available: number
  onChange: (arg0: number) => void
  disabled?: boolean
  noEdge?: boolean
}): JSX.Element {
  const [amount, setAmount] = useState(selected)
  const stock = Math.min(MAX, available)

  const increaseDisabled = selected === stock
  const decreaseDisabled = selected === 0

  function changeHandler(amount: number): void {
    const correctedAmount = Math.max(Math.min(amount, stock), 0)
    setAmount(correctedAmount)
    onChange(correctedAmount)
  }

  useEffect(() => {
    setAmount(selected)
  }, [selected])

  return (
    <div
      className={mergeClasses(
        'inline-flex flex-col relative h-full border-y border-l border-solid border-pillow',
        noEdge ? 'border-l-0 border-r last:border-r-0' : 'last:border-r'
      )}
    >
      <Divider>
        <div className="text-xs font-bold leading-none">
          {label} ({stock})
        </div>
      </Divider>
      <Divider vertical>
        <Button
          styleType="icon"
          icon="plus"
          onClick={() => changeHandler(selected + 1)}
          disabled={disabled || increaseDisabled}
        ></Button>
        <Input
          id={label}
          name={label}
          type="text"
          forceValue={String(amount)}
          center
          onChange={({ target }) => setAmount(Number(target.value))}
          onBlur={() => changeHandler(amount)}
          disabled={disabled}
          noLine
          className="[&>input]:h-full [&>input]:bg-transparent [&>input]:p-0"
        />
        <Button
          styleType="icon"
          icon="minus"
          onClick={() => changeHandler(selected - 1)}
          disabled={disabled || decreaseDisabled}
        ></Button>
      </Divider>
    </div>
  )
}
