import { rootApi } from 'core/config/klaviyo'

export type TrackType =
  | 'Added to Cart'
  | 'Cart updated'
  | 'Cart abandoned'
  | 'Reset password'
  | 'Placed Order'
  | 'Ordered Product'
  | 'Fulfilled Order'
  | 'Created Order'
  | 'Cancelled Order'
  | 'Refunded Order'
  | 'Started Checkout'

function generateOptions(
  data?: {
    token?: string
    properties?: Record<string, any>
    profiles?: Record<string, string | number>[]
    customer_properties?: Record<string, string | null>
    event?: string
  },
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'POST',
  accept: 'text' | 'json' = 'text'
): Record<string, any> {
  const { Accept, ContentType } = {
    text: {
      Accept: 'text/html',
      ContentType: 'application/x-www-form-urlencoded',
    },
    json: {
      Accept: 'application/json',
      ContentType: 'application/json',
    },
  }[accept]

  const stringified = JSON.stringify(data)
  const body = accept === 'text' ? new URLSearchParams({ data: stringified }) : stringified

  return {
    method,
    headers: { Accept, 'Content-Type': ContentType },
    body,
  }
}

export function identify(properties: Record<string, string>): void {
  const token = process.env.NEXT_PUBLIC_KLAVIYO_API
  fetch(`${rootApi}/api/identify`, generateOptions({ token, properties })).catch((err) => console.error(err))
}

export async function notify(
  customer: {
    $email?: string | null
    $first_name?: string | null
    $last_name?: string | null
    $city?: string | null
    $country?: string | null
    $organization?: string | null
    $phone_number?: string | null
    language?: string | null
  },
  type: TrackType,
  properties: {
    items?: {
      ProductID: string
      SKU: string
      ProductName: string
      VariantName: string
      Quantity: number
      ItemPrice: number
      RowTotal: number
      ProductURL: string
      ImageURL: string
      Categories: string[]
      FormattedPrice: string
    }[]
    order?: {
      id?: string
      number: string
      link?: string
      shipping?: {
        companyName?: string
        streetAddress1: string
        streetAddress2: string
        postalCode: string
        countryArea?: string
        country: string
        phone?: string | null
        carrierName: string
        shippingPrice: string
      }
      totalPrice?: string
    }
    checkout?: {
      token?: string
      link?: string
    }
    shipping?: {
      link: string
      orderNumber: string
      orderLink: string
    }
    account?: {
      passwordResetUrl: string
    }
    country?: string
    isB2B?: boolean
    Name?: string
    VariantName?: string
    SKU?: string
    Quantity?: number
    Categories?: string[]
    ProductID?: string
    $value?: number
  }
): Promise<void | Response> {
  const token = process.env.NEXT_PUBLIC_KLAVIYO_API
  return fetch(
    `${rootApi}/api/track`,
    generateOptions({ properties, customer_properties: customer, event: type, token })
  ).catch((err) => console.error(err))
}

export async function subscribeToList(
  listId: string,
  profiles: {
    email?: string
    phone_number?: string
  }[]
): Promise<void> {
  return await fetch(
    `${rootApi}/api/v2/list/${listId}/members?api_key=${process.env.KLAVIYO_PRIVATE_API}`,
    generateOptions({ profiles }, 'POST', 'json')
  )
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

export async function subscribeToVariant(variantId: string, email: string): Promise<void> {
  const KLAVIYO_API = process.env.NEXT_PUBLIC_KLAVIYO_API

  const formData = new URLSearchParams()
  formData.append('a', KLAVIYO_API)
  formData.append('email', email)
  formData.append('variant', variantId)
  formData.append('platform', 'api')

  await fetch(`${rootApi}/onsite/components/back-in-stock/subscribe`, {
    body: formData,
    method: 'POST',
  })
    .then((response) => response.json())
    .catch((err) => console.error(err))
}
