import { useRef, useState, useEffect, forwardRef, ForwardedRef, useImperativeHandle, CSSProperties } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

function Shade({ position, style }: { position: 'top' | 'bottom'; style?: CSSProperties }): JSX.Element {
  return (
    <div
      className={mergeClasses(
        'absolute left-0 right-0 h-[64px] pointer-events-none transition',
        position === 'top' ? 'top-0 bg-gradient-to-t' : 'bottom-0 bg-gradient-to-b',
        'from-ink/0 to-ink/[0.06] to-90%'
      )}
      style={style}
    ></div>
  )
}

function Scroll(
  {
    className,
    children,
    onScroll,
  }: {
    className?: string
    children: React.ReactNode
    onScroll?: () => void
  },
  ref?: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [opacity, setOpacity] = useState<{ top: number; bottom: number }>({ top: 0, bottom: 1 })

  useImperativeHandle(ref, () => scrollRef.current as HTMLDivElement)

  function handleScroll(): void {
    const overflow = (scrollRef.current?.scrollHeight || 0) - (scrollRef.current?.offsetHeight || 0)
    const scrollTop = scrollRef.current?.scrollTop || 0

    setOpacity({
      top: Math.min(scrollTop, 100) / 100,
      bottom: overflow === 0 ? 0 : Math.min(overflow - scrollTop, 100) / 100,
    })
  }

  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef])

  useEffect(() => {
    handleScroll()
  }, [children])

  return (
    <div className="relative flex h-full w-full">
      <Shade
        position="top"
        style={{ opacity: opacity.top }}
      />
      <div
        className={mergeClasses('absolute inset-0 flex h-full flex-col overflow-x-auto overflow-x-hidden', className)}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {children}
      </div>
      <Shade
        position="bottom"
        style={{ opacity: opacity.bottom }}
      />
    </div>
  )
}

export default forwardRef(Scroll)
