'use client'
import { useEffect } from 'react'

type Modifiers = 'ctrlKey' | 'shiftKey' | 'altKey'

export default function useOnDocumentKey(
  key: KeyboardEvent['key'],
  callback: () => void,
  {
    event = 'keyup',
    active = true,
    scope = [],
    modifiers,
  }: {
    event?: 'keydown' | 'keyup'
    active?: boolean
    scope?: any[]
    modifiers?: Modifiers[]
  }
): void {
  useEffect(() => {
    if (!active) return

    function handleKeyDown(event: KeyboardEvent): void {
      if (event.code === key) {
        if (!modifiers || modifiers.map((modifier) => !!event[modifier]).every(Boolean)) {
          callback()
        }
      }
    }

    document.addEventListener(event, handleKeyDown)

    return () => {
      document.removeEventListener(event, handleKeyDown)
    }
  }, [...scope, active])
}
