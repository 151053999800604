import { animated } from '@react-spring/web'

import { OverlayPositions, OverlayType } from 'core/context/OverlayProvider'
import useWindowSize from 'core/hooks/useWindowSize'
import mergeClasses from 'core/utils/mergeClasses'

import QuickBuy from 'components/QuickBuy'

import Cart from './Cart'
import Greenstory from './Greenstory'
import SizeGuide from './SizeGuide'

export default function Content({
  type,
  options: { position, context, component },
  styles,
}: {
  type: keyof typeof OverlayType
  options: {
    position: keyof typeof OverlayPositions
    context?: any
    component?: any
  }
  styles: any
}): JSX.Element {
  const { h: windowHeight } = useWindowSize()

  const getOverlayContent = (
    type: keyof typeof OverlayType | null,
    context: any,
    component: any
  ): JSX.Element | null => {
    switch (type) {
      case OverlayType.sizeChart:
        return <SizeGuide {...context} />

      case OverlayType.cart:
        return <Cart />

      case OverlayType.greenstory:
        return context?.id && <Greenstory productId={context?.id} />

      case OverlayType.quickBuy:
        return (
          context?.product?.objectID && (
            <QuickBuy
              product={context?.product}
              queryID={context?.queryID}
              trackingCategory="plp"
            />
          )
        )

      case OverlayType.component:
        return component

      default:
        return null
    }
  }

  const content = getOverlayContent(type, context, component)
  const height = ['right', 'left'].includes(position) ? `${windowHeight}px` : ''

  return (
    <animated.div
      className={mergeClasses(
        'pointer-events-auto fixed z-overlay-content',
        {
          right: 'w-full max-w-screens-sm right-0 top-0 bg-ghost',
          left: 'w-full max-w-screens-sm left-0 top-0 bg-ghost',
          bottom: 'bottom-0 left-1/2',
          center: 'top-1/2 left-1/2',
        }[position || '']
      )}
      style={{ ...styles, height }}
      role="dialog"
    >
      {content}
    </animated.div>
  )
}
