import { CartLine } from '@shopify/hydrogen-react/storefront-api-types'
import { useParams } from 'next/navigation'

import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import useUIState from 'core/hooks/useUIState'
import filterNull from 'core/utils/filterNull'
import mergeClasses from 'core/utils/mergeClasses'

import ProductItem from './ProductItem'

import type { LineType } from '../CartItem/types'

function groupLinesByProductType(lines: CartLine[]): Record<string, Algolia.Product & { lines: CartLine[] }> {
  return lines.reduce(
    (p, line) => {
      const product = line.merchandise.product
      const id = product.id.replace('gid://shopify/Product/', '')
      p[id] = p[id] || { ...product, lines: [] }
      p[id].lines.push(line)
      return p
    },
    {} as Record<string, any>
  )
}

export default function ProductList({
  lines,
  onChange,
  noScroll,
}: {
  lines: CartLine[]
  onChange: (lines: LineType[]) => Promise<void>
  noScroll?: boolean
}): JSX.Element {
  const { processing } = useUIState()
  const { channel } = useSession()
  const { locale } = (useParams() || {}) as { locale: string }

  const linesByProduct = groupLinesByProductType(lines)
  const productIds = Object.keys(linesByProduct).join(',')

  const { data: products } = useSWR<Algolia.Product[]>(
    `/api/product/variants?ids=${productIds}&channel=${channel}&language=${locale?.toUpperCase()}`
  )

  const combinedData = products?.filter(filterNull)?.map((product) => ({
    ...product,
    lines: linesByProduct[product.objectID]?.lines,
  }))

  return (
    <ol
      className={mergeClasses(
        'flex w-full flex-col grow',
        !noScroll && 'pt-lg px-md pb-md overflow-y-auto',
        processing.checkout && 'pointer-events-none opacity-50'
      )}
    >
      {combinedData?.map((product, index) => (
        <ProductItem
          key={`${product.objectID}-${index}`}
          product={product}
          onChange={onChange}
          hasRuler={index < combinedData.length - 1}
        />
      ))}
    </ol>
  )
}
