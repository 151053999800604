import { useEffect, useRef } from 'react'

import { InputProps } from 'core/types/elements'
import mergeClasses from 'core/utils/mergeClasses'

import Icon from 'components/Icon'
import PulseLoader from 'components/PulseLoader'

import Label from '../Label'

export default function Input({
  id,
  name,
  value,
  forceValue,
  label,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  type,
  autocomplete = 'off',
  uppercase,
  disabled,
  isRequired,
  isOptional,
  isInvalid,
  isLoading,
  center,
  valid,
  autoFocus,
  noLine,
  className,
}: InputProps & { className?: string; forceValue?: string }): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)
  const isActive = !!value
  let inputValue

  if (value) {
    inputValue = value
  }

  useEffect(() => {
    autoFocus && inputRef?.current?.focus()
  }, [])

  return (
    <div
      className={mergeClasses(
        'group w-full max-w-input flex relative focus-sm ',
        !noLine &&
          `before:content-[''] before:absolute before:left-0 before:bottom-0 before:right-0 before:block  before:transition-all before:duration-150 before:bg-pillow`,
        isInvalid ? `before:bg-crimson before:h-[2px]` : 'before:h-[1px]',
        'focus-within:before:h-[2px] focus-within:before:bg-ink',
        disabled && 'before:bg-disabled',
        className
      )}
    >
      <input
        className={mergeClasses(
          ' outline-0 text-xl leading-0 text-ink w-full border-0 rounded-0 text-ellipsis white-space-nowrap overflow-hidden bg-ghost no-highlight',
          !!label ? 'py-[20px]' : 'py-sm',
          'px-xs pb-xs',
          'transition-all duration-150',
          '[&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none',
          '[&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none',
          '[&:-webkit-autofill+label]:-translate-y-[20px] [&:-webkit-autofill+label]:text-sm [&:-webkit-autofill+label]:text-unfocus',
          center && 'text-center'
        )}
        ref={inputRef}
        defaultValue={inputValue}
        value={forceValue}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(event): void => {
          if (uppercase) {
            event.target.value = event.target.value.toUpperCase()
          }
          onChange && onChange(event)
        }}
        style={(uppercase && { textTransform: 'uppercase' }) || {}}
        type={type}
        autoComplete={autocomplete}
        disabled={disabled}
        placeholder={placeholder}
      />
      {label && (
        <Label
          htmlFor={id}
          isRequired={isRequired}
          isOptional={isOptional}
          active={isActive}
          className={mergeClasses(
            'group-focus-within:-translate-y-[20px] group-focus-within:text-sm group-focus-within:text-unfocus',
            (isActive || placeholder) && '-translate-y-[20px] text-sm text-unfocus',
            disabled && '!text-disabled'
          )}
        >
          {label}
        </Label>
      )}

      {isLoading && (
        <PulseLoader
          className="bottom-md left-auto right-xs top-auto"
          color="pillow"
          amount={2}
        />
      )}
      {valid && (
        <Icon
          name="check"
          className="absolute bottom-xs right-xs"
          color={disabled ? 'disabled' : 'grass'}
        />
      )}
    </div>
  )
}
