import Button from 'components/Button'

export default function LinkGroup({
  title,
  links,
}: {
  title: string | JSX.Element
  links?: { name: string; slug: string; id: string }[]
}): JSX.Element {
  return (
    <>
      <h5 className="mb-md text-md leading-4">{title}</h5>
      {links?.map(({ name, slug, id }, index: number) => (
        <Button
          className="mb-sm"
          styleType="link"
          key={`${id}-${index}`}
          to={slug}
        >
          {name}
        </Button>
      ))}
    </>
  )
}
