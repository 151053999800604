import mergeClasses from 'core/utils/mergeClasses'

function convertNumbersInStringToInches(value: string): string {
  return value?.replace(/(\d+[.,]?\d*)/g, (_, capture) => {
    return (parseInt(capture, 10) * 0.3937).toFixed(2)
  })
}

export default function Column({
  column,
  data,
  isLegend,
  convert,
}: {
  column: string
  data?: string[]
  isLegend?: boolean
  convert?: boolean
}): JSX.Element {
  const rowClasses = mergeClasses(
    'flex flex-row p-md text-center fist:font-bold first:bg-cotton',
    isLegend ? 'bg-cotton justify-start font-bold' : 'bg-ghost justify-center'
  )
  return (
    <>
      <div className={mergeClasses('flex flex-col', isLegend ? 'bg-cotton ' : 'bg-ghost flex-grow')}>
        <div className={rowClasses}>{column}</div>
        {data?.map((row, index) => (
          <div
            className={rowClasses}
            key={index}
          >
            {convert && !isLegend ? convertNumbersInStringToInches(row) : row}
          </div>
        ))}
      </div>
    </>
  )
}
