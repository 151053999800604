import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

import getClient, { graphql as customerGql } from 'core/clients/graphql/customer'
import useSession from 'core/hooks/useSession'
import { SessionType } from 'core/types/session'
import logout from 'core/utils/logout'

type UserType = {
  id: string
  email: string
  firstName: string
  lastName: string
  countryCode: string
}

const query = customerGql(`
  #graphql
  query getUserFromToken {
    customer {
      id
      emailAddress {
        emailAddress
      }

      defaultAddress {
        firstName
        lastName
        territoryCode
        phoneNumber
      }
    }
  }
`)

async function validateToken(token: string, onComplete?: (arg0?: UserType | null) => void): Promise<void> {
  const client = getClient(token)
  const { data, error } = await client.query(query, {
    token,
  })

  if (!data?.customer || error) {
    Cookies.remove(SessionType.StorageKeys.AuthTokenId)
    Cookies.remove(SessionType.StorageKeys.AuthToken)
    Cookies.remove(SessionType.StorageKeys.AuthRefreshToken)

    Cookies.remove(SessionType.StorageKeys.Catalog)
    Cookies.remove(SessionType.StorageKeys.Location)

    Cookies.remove(SessionType.StorageKeys.StorefrontToken)
    Cookies.remove(SessionType.StorageKeys.CustomerToken)
    onComplete && onComplete(null)
    return
  }

  const user: UserType = {
    id: data?.customer?.id as string,
    email: data?.customer?.emailAddress?.emailAddress as string,
    firstName: data?.customer?.defaultAddress?.firstName as string,
    lastName: data?.customer?.defaultAddress?.lastName as string,
    countryCode: data?.customer?.defaultAddress?.territoryCode as string,
  }

  onComplete && onComplete(user)
}

export default function useAccount(): {
  user: UserType | null
  loading: boolean
  isLoggedIn: boolean
  logout: () => void
} {
  const { customerToken } = useSession()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<UserType | null>(null)

  useEffect(() => {
    if (!customerToken) {
      setLoading(false)
    } else {
      setLoading(true)
      validateToken(customerToken, (user) => {
        setUser(user || null)
        setLoading(false)
      })
    }
  }, [customerToken])

  return {
    loading,
    user,
    isLoggedIn: !!user?.email,
    logout: async () => {
      logout()

      // await apolloClient.refetchQueries({
      //   include: [AccountDocument, 'CheckoutForm'],
      // })
    },
  }
}
