import { useParams } from 'next/navigation'
import algolia from 'search-insights'

import { Channel } from 'core/channel'
import { getIndexKey } from 'core/clients/algolia'
import { genderSlugCollections } from 'core/config/collections'
import useCheckoutUpdateLinesMutation from 'core/hooks/useCheckoutUpdateLinesMutation'
import useI18n from 'core/hooks/useI18n'
import useNotifications from 'core/hooks/useNotifications'
import useOverlayControls from 'core/hooks/useOverlayControls'
import useProductParams from 'core/hooks/useProductParams'
import useProductSelection, { processSelection } from 'core/hooks/useProductSelection'
import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import { AnalyticsMutation } from 'core/types/tracking'
// import stringToBoolean from 'core/utils/stringToBoolean'
import { analyticsTracking } from 'core/utils/tracking'

import type { ReturnType } from 'app/api/product/availability/route'

import Button from 'components/Button'
import Preorder from 'components/Preorder'
import ProductInfoCondensed from 'components/ProductInfoCondensed'
import SizeSelector from 'components/SizeSelector'

export default function QuickBuy({
  product,
  compressed,
  trackingCategory,
  queryID,
}: {
  product: Algolia.Product
  compressed?: boolean
  trackingCategory?: AnalyticsMutation['category']
  queryID?: string
}): JSX.Element | null {
  const { gender: _gender } = useProductParams()
  const { locale, channel } = (useParams() as { locale: string; channel: Channel }) || {}
  const { t } = useI18n()
  const { algoliaUserToken, location } = useSession()
  const { show, hide } = useOverlayControls()
  const { add } = useNotifications()
  // const isB2B = stringToBoolean(process.env.NEXT_PUBLIC_B2B)
  const [updateLines] = useCheckoutUpdateLinesMutation()
  const shouldLoad = channel && locale && product.objectID

  const { data } = useSWR<ReturnType>(
    shouldLoad
      ? `/api/product/availability?channel=${channel}&locale=${locale}&id=${product.objectID}&location=${location}`
      : null
  )
  const { availability } = data || { availability: null }

  const selector = useProductSelection(availability || product.variants)
  if (!selector) {
    return null
  }
  const { sizes, sizesAvailable, selection, resetSelection } = selector

  const gender = _gender || product.categories.find((collection) => genderSlugCollections.includes(collection))

  return (
    <div className="h-auto min-w-[300px] max-w-[95vw] overflow-hidden rounded-t-2xl bg-ghost p-md sm:max-w-screens-sm">
      <Button
        styleType="base"
        className="focus mb-md"
        to={product.link}
      >
        <ProductInfoCondensed
          withImage={!compressed}
          product={product}
        />
      </Button>
      <Preorder
        date={product.preorderAt}
        small
      />
      {!compressed && (
        <div className="mb-md flex flex-row content-center items-center justify-between text-md font-bold">
          {t('pdp.size')}
          <Button
            className="text-md"
            icon="measure"
            styleType="link"
            onClick={() =>
              show('sizeChart', {
                context: {
                  category: product?.category,
                  gender,
                  collections: product.collections,
                },
              })
            }
          >
            {t('pdp.size-guide')}
          </Button>
        </div>
      )}
      <SizeSelector
        sizes={sizes}
        sizesSelected={selection}
        sizesAvailable={sizesAvailable}
        withLoadingOnClick
        onChange={async (selection): Promise<void> => {
          const lines = processSelection(product.variants, selection)?.filter(({ quantity }) => quantity > 0)
          if (lines) {
            const { opencart } = await updateLines(lines)
            if (opencart) {
              show('cart')
            } else {
              hide()
              add({ type: 'added-cart', context: product })
            }

            trackingCategory && analyticsTracking({ action: 'click', category: trackingCategory, label: 'quick-buy' })

            if (queryID) {
              algolia('convertedObjectIDsAfterSearch', {
                userToken: algoliaUserToken,
                index: getIndexKey(),
                eventName: 'Product Added to Cart',
                objectIDs: [product.objectID],
                queryID,
              })
            } else {
              algolia('convertedObjectIDs', {
                userToken: algoliaUserToken,
                index: getIndexKey(),
                eventName: 'Product Added to Cart',
                objectIDs: [product.objectID],
              })
            }

            resetSelection()
          }
        }}
      />
    </div>
  )
}
