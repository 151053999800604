import useI18n from 'core/hooks/useI18n'
import useSWR from 'core/hooks/useSWR'

import type { ReturnType } from 'app/api/product/greenstory/route'

import GreenstoryProject from 'components/GreenstoryProject'
import Scroll from 'components/Scroll'

import CloseBar from '../Common/CloseBar'

export default function Greenstory({ productId }: { productId: string }): JSX.Element {
  const { t } = useI18n()
  const { data } = useSWR<ReturnType>(`/api/product/greenstory?id=${productId}`)
  const metric = data?.offset_summary?.metrics[0]

  return (
    <div className="flex h-[inherit] grow flex-col">
      <CloseBar align="right" />

      {metric && (
        <Scroll>
          <div className="p-md">
            <h3 className="mb-md">
              {t('pdp.greenstory.project.header')} {`${metric.value.toFixed(2)} ${metric.units} ${metric.name}`}
            </h3>
            <div>{t('pdp.greenstory.project.subheader')}</div>
          </div>
          <ul className="p-md">
            {metric?.project_distribution.map((project, index) => (
              <GreenstoryProject
                key={index}
                {...project}
                units={metric.units}
              />
            ))}
          </ul>
        </Scroll>
      )}
    </div>
  )
}
