import { FormikProps, useFormikContext, ErrorMessage } from 'formik'

import useI18n from 'core/hooks/useI18n'

export function LocalisedErrorMessage({ children }: { children?: keyof I18n.Keys }): JSX.Element | null {
  const { t } = useI18n()

  if (!children) return null
  return <div className="absolute bottom-0 pl-xs pt-xs text-left text-sm text-crimson">{t(children)}</div>
}

const UntouchedErrorMessage: React.FC<{ name: string }> = ({ name }) => {
  const { errors }: FormikProps<any> = useFormikContext()

  return errors[name] ? <LocalisedErrorMessage>{errors[name] as keyof I18n.Keys}</LocalisedErrorMessage> : null
}

export default function FormikErrorMessage({ name, touched }: { name: string; touched?: boolean }): JSX.Element {
  return touched ? (
    <UntouchedErrorMessage name={name} />
  ) : (
    <ErrorMessage
      name={name}
      component={LocalisedErrorMessage}
    />
  )
}
