import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'

function SocialButton({ className, ...props }: (typeof Button)['arguments']): JSX.Element {
  return (
    <Button
      styleType="icon"
      className={mergeClasses(
        '!inline-flex !h-[40px] !w-[40px] border border-solid border-pillow focus-rd-xl',
        className
      )}
      {...props}
    />
  )
}

export default function Social(): JSX.Element {
  return (
    <div className="my-lg">
      <SocialButton
        styleType="icon"
        className="mr-sm"
        icon="facebook"
        to="https://www.facebook.com/adamunderwearofficial/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </SocialButton>
      <SocialButton
        className="mr-sm"
        icon="instagram"
        to="https://www.instagram.com/adam.official/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </SocialButton>
      <SocialButton
        icon="spotify"
        to="https://open.spotify.com/playlist/1eWTreyrOiTuGZA2IcJD8e?si=GxV6yfx3QH-3dl3qFktf-A"
        target="_blank"
        rel="noopener noreferrer"
      >
        Spotify
      </SocialButton>
    </div>
  )
}
