import { useParams } from 'next/navigation'

import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import { chunk } from 'core/utils/language'
import mergeClasses from 'core/utils/mergeClasses'

import Image from 'components/Image'
import PulseLoader from 'components/PulseLoader'

export default function PaymentMethods({
  amountPerRow = 5,
  center,
}: {
  amountPerRow?: number
  center?: boolean
}): JSX.Element {
  const { locale } = useParams() || {}
  const { shippingCountry } = useSession()
  const { data: availablePayments } = useSWR<
    {
      image: string
      name: string
      fit?: 'contain' | 'cover'
    }[]
  >(`/api/store/payment-methods?country=${shippingCountry}&language=${(locale as string)?.toUpperCase()}`)

  return (
    <div>
      {!!availablePayments ? (
        <>
          {chunk(availablePayments, amountPerRow).map((methods, i) => {
            return (
              <div
                key={i}
                className={mergeClasses('flex w-full not-last:mb-md', center && 'justify-center')}
              >
                {methods.map(
                  (method, index) =>
                    method?.image && (
                      <Image
                        className="inline-flex !h-[25px] !w-xl object-contain not-last:mr-sm"
                        alt={method.name}
                        key={index}
                        objectFit={method.fit}
                        src={method?.image}
                      />
                    )
                )}
              </div>
            )
          })}
        </>
      ) : (
        <div>
          <PulseLoader color="ink" />
        </div>
      )}
    </div>
  )
}
