import { Form as FormikForm, Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import useI18n from 'core/hooks/useI18n'
import useKlaviyo from 'core/hooks/useKlaviyo'
import session from 'core/session'
import { AnalyticsCategory } from 'core/types/tracking'
import newsletterSubscribe from 'core/utils/newsletterSubscribe'
import { analyticsTracking } from 'core/utils/tracking'

import Button from 'components/Button'
import FormikField from 'components/FormikField'
import GeneralFormError from 'components/GeneralFormError'
import Image from 'components/Image'
import Input from 'components/Input'

const formSchema = yup.object({
  email: yup.string().email('checkout.errors.invalid-email').defined('checkout.error.required-field'),
})

function StyledInput(props: React.ComponentProps<typeof Input>): JSX.Element {
  return (
    <Input
      {...props}
      className="before:bg-ink hover:before:bg-ink-hover [&>input]:bg-transparent"
    />
  )
}

export default function SubscribeAndFollow({
  title,
  description,
  image,
  trackingCategory,
  onSubscribe,
  className,
}: {
  title?: string
  description?: string
  image?: string
  trackingCategory?: keyof typeof AnalyticsCategory
  onSubscribe?: () => void
  className?: string
}): JSX.Element {
  const { identify } = useKlaviyo()
  const { t } = useI18n()
  const [success, setSuccess] = useState(false)

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async ({ email }) => {
        await newsletterSubscribe({ email })
        session.email = email
        session.subscribed = true
        identify()
        setSuccess(true)

        trackingCategory &&
          analyticsTracking({
            action: 'subscribe',
            category: trackingCategory,
          })

        onSubscribe && onSubscribe()
      }}
      validateOnChange
      validationSchema={formSchema}
      enableReinitialize={false}
    >
      {({ isSubmitting, isValid, errors }): JSX.Element => (
        <FormikForm
          id="subscribe"
          className={className}
        >
          <div className="m-auto flex h-full max-w-[1000px] flex-col items-center justify-center p-lg">
            {image && (
              <div className="flex w-[100px] justify-center">
                <Image
                  src={image}
                  alt=""
                />
              </div>
            )}

            <div className="flex w-full max-w-[500xp] flex-col justify-center">
              {title && <h4 className="mb-sm text-center">{title}</h4>}
              {description && <p className="mb-sm text-center">{description}</p>}
              <div className="mb-sm flex w-full items-center justify-center text-center ">
                <FormikField
                  name="email"
                  type="email"
                  isRequired={true}
                  isOptional={false}
                  label={t('checkout.input.email.label')}
                  component={StyledInput}
                  autocomplete="email"
                  className="relative mb-md mr-sm !w-[250px]"
                />
                <GeneralFormError />

                <Button
                  type="submit"
                  loading={isSubmitting}
                  icon={success ? 'check' : 'mail'}
                  styleType="icon"
                  inverted
                  disabled={!((Object.keys(errors).includes('form') && Object.keys(errors).length === 1) || isValid)}
                  className="relative mb-md mr-sm "
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}
