import { useParams } from 'next/navigation'

import { Channel } from 'core/channel'
import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import { getPricingFromChannel } from 'core/utils/channel'

import type { ReturnType } from 'app/api/product/availability/route'

import Image from 'components/Image'
import Price from 'components/Price'

export default function ProductInfoCondensed({
  product,
  withImage,
}: {
  product: Algolia.Product
  withImage: boolean
}): JSX.Element {
  const { location } = useSession()
  const { locale, channel } = (useParams() as { locale: string; channel: Channel }) || {}
  const { data } = useSWR<ReturnType>(
    channel && locale
      ? `/api/product/availability?channel=${channel}&locale=${locale}&id=${product.objectID}&location=${location}`
      : null
  )
  const { pricing } = data || { pricing: null }

  const { price, compareAtPrice, currency } = pricing || getPricingFromChannel(product, channel) || {}
  return (
    <div className="flex flex-row content-center items-center">
      {withImage && (
        <Image
          className="size-[48px] overflow-hidden rounded-xl"
          src={product.thumbnail}
          alt={product.title}
          objectFit="cover"
        />
      )}
      <div className="ml-sm flex flex-col text-lg font-bold ">
        {product.title}
        <Price
          price={price || 0}
          undiscountedPrice={product.onSale ? compareAtPrice : null}
          currencyCode={currency || 'EUR'}
          large
          center
        />
      </div>
    </div>
  )
}
