import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'
import { useRouter, usePathname, useParams } from 'next/navigation'

import { defaultCountry } from 'core/config/countries'
import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import session from 'core/session'
import { getChannel } from 'core/utils/channel'

import type { CountriesResultType } from 'app/api/store/countries/route'

type ShippingCountry = {
  /** Country code. */
  code: CountryCode
  /** Country name. */
  name: string
  /** Country tax. */
  vat?: string
  /** Minimum delivery time in days. */
  minDeliveryDays?: string
  /** Maximum delivery time in days */
  maxDeliveryDays?: string
  /** Shipping methods available for the country. */
  shippingMethods?: any[]
}

function useShippingCountries(): CountriesResultType | null {
  const { data } = useSWR<CountriesResultType>(`/api/store/countries`)

  return data || null
}

export default function useShippingCountry(): {
  shippingCountry: ShippingCountry
  setShippingCountry: (code: CountryCode, redirect?: boolean) => void
  shippingCountries: {
    code: CountryCode
    name: string
  }[]
} {
  const { shippingCountry: sessionShippingCountry } = useSession()
  const { locale } = useParams() || {}
  const countries = useShippingCountries()
  const { push, refresh } = useRouter()
  const pathname = usePathname()

  const regionNamesInLocale = new Intl.DisplayNames([locale as string], { type: 'region' })

  async function setShippingCountry(country: ShippingCountry | string, redirect = false): Promise<void> {
    const code = (typeof country === 'string' ? country : country?.code) as CountryCode

    if (getChannel(code) !== getChannel(sessionShippingCountry) && redirect) {
      // TODO: notify user on channel change and thus creating a new cart
      session.shippingCountry = code as CountryCode
      pathname && push(pathname)
      refresh()
    } else {
      session.shippingCountry = code as CountryCode
    }
  }

  const shippingCountries =
    countries?.map((countryCode) => ({
      code: countryCode,
      name: regionNamesInLocale.of(countryCode) as string,
    })) || []

  shippingCountries.sort((a, b) => a.name.localeCompare(b.name))

  const shippingCountry = shippingCountries?.find(
    ({ code }) => code.toLowerCase() === sessionShippingCountry.toLowerCase()
  )

  return {
    shippingCountry: shippingCountry || {
      code: defaultCountry,
      name: regionNamesInLocale.of(defaultCountry) as string,
    },
    shippingCountries,
    setShippingCountry,
  }
}
