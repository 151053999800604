import { Contact } from 'core/server/addressValidation'

export default async function newsletterSubscribe(contact: Contact): Promise<Response | void> {
  return fetch('/api/account/newsletter', {
    method: 'POST',
    body: JSON.stringify(contact),
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }).catch(() => console.error('Newsletter subscription failed'))
}
