'use client'
import { useState, useEffect } from 'react'

export default function Client({ children }: { children: JSX.Element | JSX.Element[] | null }): JSX.Element | null {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return <>{children}</>
}
