import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'

export default function Crumb({ large, ...props }: { large?: boolean } & PropsOf<typeof Button>): JSX.Element {
  return (
    <Button
      styleType="base"
      {...props}
      className={mergeClasses(
        'inline-flex p-0 disabled:text-disabled text-disabled hover:text-ink focus-md',
        large ? 'text-lg' : 'text-md'
      )}
    />
  )
}
