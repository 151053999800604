import mergeClasses from 'core/utils/mergeClasses'

import Icon from 'components/Icon'

import type { IconType } from 'components/Icon'

type DropDownOption = {
  value: string
  label: string
}

type Props<T extends DropDownOption> = {
  onSelect: (option: string) => void
  options: T[]
  selected: DropDownOption
  icon?: IconType | null
  className?: string
  cyName?: string
}

export default function DropDown<T extends DropDownOption>(props: Props<T>): JSX.Element {
  const { onSelect, selected, options, icon = null, className = '', cyName } = props
  return (
    <div className={mergeClasses('flex relative items-center focus', className)}>
      {icon && <Icon name={icon} />}
      <select
        data-cy={cyName}
        onChange={(event): void => {
          onSelect(event.target.value)
        }}
        value={selected.value}
        className="absolute inset-0 size-full cursor-pointer opacity-0"
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </option>
        ))}
      </select>
      <label className="text-md font-bold">{selected.label}</label>
    </div>
  )
}
