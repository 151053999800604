import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'

import Crumb from './Crumb'

function Slash({ large }: { large?: boolean }): JSX.Element {
  return (
    <span className={mergeClasses('my-0 mx-xs text-disabled whitespace-nowrap', large ? 'text-lg' : 'text-md')}>/</span>
  )
}

export default function Breadcrumbs({
  ancestors = [],
  category,
  onClick,
  large,
  withBack,
}: {
  ancestors: {
    name: string | null
    url: string | null
  }[]
  gender?: string | null
  category?: string | null
  onClick?: (slug: string | null, isGender: boolean) => void
  large?: boolean
  withBack?: boolean
}): JSX.Element | null {
  if (!category) {
    return null
  }

  const crumbs = category ? [...ancestors, { name: category, url: null }] : ancestors

  const total = crumbs.length
  const backParent = crumbs[crumbs.length]

  return (
    <div className="mx-0 my-sm flex min-h-lg items-center">
      {withBack && (
        <Button
          className="mr-sm"
          styleType="icon"
          icon="chevronLeft"
          onClick={() => onClick && onClick(backParent?.url as string, total === 1)}
        >
          {backParent?.name}
        </Button>
      )}
      <span className="whitespace-nowrap">
        <Crumb
          large={large}
          to={!onClick ? '/' : null}
          onClick={() => onClick && onClick(null, true)}
        >
          Home
        </Crumb>
      </span>
      <Slash large={large} />
      {crumbs?.map(({ name, url }, index) => (
        <span
          className="whitespace-nowrap"
          key={index}
        >
          <Crumb
            disabled={index === total - 1}
            to={!onClick ? url : null}
            onClick={() => onClick && onClick(url, index === 0)}
            large={large}
          >
            {name}
          </Crumb>
          {index !== total - 1 && <Slash large={large} />}
        </span>
      ))}
    </div>
  )
}
