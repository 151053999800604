import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'

import useIsWholesale from 'core/hooks/useIsWholesale'
import useShippingCountry from 'core/hooks/useShippingCountry'
import mergeClasses from 'core/utils/mergeClasses'

import Client from 'components/Client'
import DropDown from 'components/DropDown'
import LanguageSelect from 'components/LanguageSelect'

export default function LocaleSelector({ className }: { className?: string }): JSX.Element {
  const isWholesale = useIsWholesale()
  const { shippingCountry, setShippingCountry, shippingCountries } = useShippingCountry()

  const countriesOptions =
    shippingCountries?.map((country) => ({
      label: country.name,
      value: country.code,
    })) || []

  return (
    <div className={mergeClasses('flex items-center', className)}>
      <Client>
        <>
          <LanguageSelect className="mr-sm" />
          {!isWholesale && (
            <>
              <>|</>
              <DropDown
                className="ml-sm"
                onSelect={(country) => setShippingCountry(country as CountryCode, true)}
                options={countriesOptions}
                selected={{ value: shippingCountry?.code, label: shippingCountry.code }}
                icon="delivery"
              />
            </>
          )}
        </>
      </Client>
    </div>
  )
}
