import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'
import { useParams } from 'next/navigation'
import { useState } from 'react'

import useShippingCountry from 'core/hooks/useShippingCountry'
import useSWR from 'core/hooks/useSWR'

import type { ReturnType } from 'app/api/product/sizeguide/route'

import Button from 'components/Button'
import Media from 'components/DatoPage/blocks/components/Media'
import PulseLoader from 'components/PulseLoader'
import Scroll from 'components/Scroll'

import CloseBar from '../Common/CloseBar'

import Column from './Column'

const maxPerPage = 3
const inchCountries: CountryCode[] = ['US', 'GB']

export default function SizeGuide({
  gender,
  category,
  collections,
}: {
  gender?: string
  category?: string
  collections?: string[]
}): JSX.Element | null {
  const { shippingCountry } = useShippingCountry()
  const { locale } = useParams() || {}
  const [currentPage, setCurrentPage] = useState(0)
  const [unit, setUnit] = useState<'CM' | 'IN'>(inchCountries.includes(shippingCountry.code) ? 'IN' : 'CM')

  const { data, isLoading } = useSWR<ReturnType>(
    `/api/product/sizeguide?locale=${locale}&gender=${gender}&category=${category}&collections=${collections?.join(
      ','
    )}`
  )

  if (!data?.guide || isLoading) {
    return (
      <div className="flex grow flex-col ">
        <CloseBar align="right" />
        <div className="flex h-full items-center justify-center">
          <PulseLoader
            color="ink"
            amount={3}
          />
        </div>
      </div>
    )
  }

  const canConvert = data?.canConvert
  const hasColumnLegend = data?.hasColumnLegend

  const columns = data?.guide.columns.slice(hasColumnLegend ? 1 : 0) || []
  const legend = hasColumnLegend ? data?.guide.columns.slice(0, 1) || [] : []
  const maxPage = Math.round(columns.length / maxPerPage) - 1
  const pageColumns = [...columns].splice(currentPage * maxPerPage, maxPerPage)

  const prevPage = currentPage - 1 < 0 ? 0 : currentPage - 1
  const nextPage = currentPage + 1 > maxPage ? currentPage : currentPage + 1

  const prevPageButton = [...columns].splice(prevPage * maxPerPage, maxPerPage).join(', ')
  const nextPageButton = [...columns].splice(nextPage * maxPerPage, maxPerPage).join(', ')

  const convertToInches = canConvert && unit === 'IN'

  return (
    <div className="flex h-[inherit] grow flex-col">
      <CloseBar align="right">
        {canConvert && (
          <div className="flex">
            <Button
              className="mx-xs"
              styleType="text"
              disabled={unit === 'CM'}
              onClick={() => setUnit('CM')}
            >
              CM
            </Button>
            /
            <Button
              className="mx-xs"
              styleType="text"
              disabled={unit === 'IN'}
              onClick={() => setUnit('IN')}
            >
              IN
            </Button>
          </div>
        )}
      </CloseBar>
      <Scroll>
        {data?.medium && (
          <Media
            blur={false}
            {...data?.medium}
          />
        )}
        {maxPage > 0 && (
          <div className="flex w-full justify-center">
            <Button
              className="mx-xs my-md"
              styleType="small-button"
              disabled={currentPage === 0}
              onClick={() => setCurrentPage(prevPage)}
            >
              {prevPageButton}
            </Button>
            <Button
              className="mx-xs my-md"
              styleType="small-button"
              disabled={currentPage === maxPage}
              onClick={() => setCurrentPage(nextPage)}
            >
              {nextPageButton}
            </Button>
          </div>
        )}
        <div className="flex w-full flex-row">
          {legend?.map((column, index) => (
            <Column
              isLegend
              key={index}
              column={column}
              convert={convertToInches}
              data={data?.guide.data.map((row) => row[column] as string)}
            />
          ))}
          {pageColumns?.map((column, index) => (
            <Column
              key={index}
              column={column}
              convert={convertToInches}
              data={data?.guide.data.map((row) => row[column] as string)}
            />
          ))}
        </div>
      </Scroll>
    </div>
  )
}
