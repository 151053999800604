import { useMenuContext } from 'core/context/MenuProvider'
import useI18n from 'core/hooks/useI18n'
import useIsInViewport from 'core/hooks/useIsInViewport'
import mergeClasses from 'core/utils/mergeClasses'
import stringToBoolean from 'core/utils/stringToBoolean'

import Button from 'components/Button'
import Social from 'components/Footer/Social'
import LocaleSelector from 'components/LocaleSelector'
import PaymentMethods from 'components/PaymentMethods'

import LinkGroup from './LinkGroup'
import Subscribe from './Subscribe'

function LinkGroupContainer({ children, className }: { children: React.ReactNode; className?: string }): JSX.Element {
  return (
    <div
      className={mergeClasses(
        'flex flex-col items-start border-solid border-pillow p-md not-last:border-b',
        'md:w-1/2 md:!border-b-0 lg:w-1/4',
        className
      )}
    >
      {children}
    </div>
  )
}

type Link = { name: string; slug: string; id: string }

export default function Footer(): JSX.Element {
  const menuData = useMenuContext()
  const { t } = useI18n()
  const links = menuData?.links
  const showMoreDetails = useIsInViewport('md')

  const rootedLinks = links?.map(({ slug, ...rest }) => ({ ...rest, slug: `/${slug}` }))
  const usefulLinks =
    rootedLinks
      ?.filter(({ showInFooter }) => showInFooter === 'useful')
      ?.map(
        ({ title, slug }) =>
          ({
            name: title,
            slug,
          }) as Link
      ) || []

  const aboutLinks =
    rootedLinks
      ?.filter(({ showInFooter }) => showInFooter === 'about')
      ?.map(
        ({ title, slug }) =>
          ({
            name: title,
            slug,
          }) as Link
      ) || []

  usefulLinks.push({ id: 'stores', name: t('footer.stores'), slug: '/stores' })

  if (!stringToBoolean(process.env.NEXT_PUBLIC_B2B)) {
    usefulLinks.push({ id: 'b2b', name: 'B2B', slug: 'https://b2b.a-dam.com' })
  }

  return (
    <>
      <div className="mt-2xl w-full bg-ghost">
        <Subscribe />
        <div className="relative m-auto flex w-full max-w-content flex-col flex-wrap justify-center md:flex-row md:justify-between md:p-lg md:text-left lg:p-xl">
          <LinkGroupContainer className="flex-col justify-end md:flex-col-reverse">
            <Social />
            <div>
              <h5 className="mb-md text-md leading-4">A-dam</h5>
              <p className="max-w-[75vw] text-md leading-5 md:max-w-[30vw]">{t('footer.description')}</p>
            </div>
          </LinkGroupContainer>
          <LinkGroupContainer>
            <h5 className="mb-md text-md leading-4">{t('footer.contact')}</h5>
            <ol>
              <li className="mb-md">
                <p>{t('footer.email')}</p>
                <Button
                  className="mb-md"
                  styleType="link"
                  target="_blank"
                  to={'mailto:support@a-dam.com'}
                >
                  support@a-dam.com
                </Button>
              </li>
              <li className="mb-md">
                <p>{t('footer.call')}</p>
                <Button
                  className="mb-sm"
                  styleType="link"
                  target="_blank"
                  to={'tel:+31 20 261 1896'}
                >
                  +31(0)20 261 1896
                </Button>
              </li>
              <li>
                <p>{t('footer.opening')}</p>
                <p> {`${t('footer.mon-fri')} 9:00 - 19:00 CET`}</p>
              </li>
            </ol>
          </LinkGroupContainer>
          {showMoreDetails && (
            <>
              {usefulLinks?.length > 0 && (
                <LinkGroupContainer>
                  <LinkGroup
                    title={t('footer.useful')}
                    links={usefulLinks}
                  />
                  <div className="mt-md">
                    <PaymentMethods amountPerRow={6} />
                  </div>
                </LinkGroupContainer>
              )}
              {aboutLinks?.length > 0 && (
                <LinkGroupContainer>
                  <LinkGroup
                    title={t('footer.about')}
                    links={aboutLinks}
                  />
                </LinkGroupContainer>
              )}
            </>
          )}
          {!showMoreDetails && (
            <LinkGroupContainer>
              <PaymentMethods amountPerRow={6} />
            </LinkGroupContainer>
          )}
        </div>
      </div>
      <div className="flex h-auto w-full flex-col items-start border-t border-solid border-pillow bg-cotton p-md !pr-[80px] text-sm md:flex-row md:items-center md:justify-between md:px-lg md:py-xs">
        © A-dam Underwear B.V.
        {showMoreDetails && <LocaleSelector />}
      </div>
    </>
  )
}
