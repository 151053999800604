import { useState, useEffect } from 'react'

import { SizesType, SizeSelectorType } from 'components/SizeSelector'

import SizeSelector from './SizeSelector'

export default function SizeSelectorBulk({
  sizes,
  sizesAvailable,
  sizesSelected,
  onChange,
  edgeToEdge,
}: SizeSelectorType & {
  edgeToEdge?: boolean
}): JSX.Element {
  const [items, setItems] = useState<SizesType>(sizesSelected)

  function changeHandler(items: SizesType): void {
    setItems(items)
    onChange && onChange(items)
  }

  useEffect(() => {
    setItems(sizesSelected)
  }, [sizesSelected])

  return (
    <div className="flex">
      {sizes.map((size: keyof SizesType) => {
        const selected = items[size]
        const available = sizesAvailable[size]
        return (
          <SizeSelector
            key={size}
            selected={selected || 0}
            label={size}
            available={available || 0}
            onChange={(value) => changeHandler({ ...items, [size]: value })}
            disabled={available === 0}
            noEdge={edgeToEdge}
          />
        )
      })}
    </div>
  )
}
