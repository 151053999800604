import { CartLine } from '@shopify/hydrogen-react/storefront-api-types'
import { useEffect, useState } from 'react'

import useI18n from 'core/hooks/useI18n'
import useProductSelection, { processSelection } from 'core/hooks/useProductSelection'
import useSession from 'core/hooks/useSession'
import type { sizeType } from 'core/types/size'
import { getPricingFromCountry } from 'core/utils/channel'
import filterNull from 'core/utils/filterNull'
import removeTaxIfApplicable from 'core/utils/removeTaxIfApplicable'

import viewports from 'components/AdamTheme/theme/viewports'
import Button from 'components/Button'
import Image from 'components/Image'
import Price from 'components/Price'
import SizeSelectorBulk from 'components/SizeSelectorBulk'

import type { LineType } from '../CartItem/types'
import type { SizesType } from 'components/SizeSelector'

export default function ProductItem({
  product,
  onChange,
  hasRuler,
}: {
  product: Algolia.Product & { lines?: CartLine[] }
  hasRuler?: boolean
  onChange: (arg0: LineType[]) => void
}): JSX.Element | null {
  const { t } = useI18n()

  const { sizes, sizesAvailable, setSelection, lines, selection, emptySelection } =
    useProductSelection(product.variants) || {}

  const [totalPrice, setTotalPrice] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const { shippingCountry, channel } = useSession()
  const pricing = getPricingFromCountry(product, shippingCountry)
  const currency = pricing?.currency || 'EUR'

  useEffect(() => {
    const parsedSelection: SizesType = { ...emptySelection }

    product.lines?.forEach((line: CartLine) => {
      const variant = product?.variants?.find(
        ({ id }) => id === line.merchandise.id.replace('gid://shopify/ProductVariant/', '')
      )

      if (variant) {
        parsedSelection[variant.title as sizeType] = line.quantity
      }
    })

    const totalPrice =
      product.lines?.reduce((sum, line) => {
        const amount = removeTaxIfApplicable(channel, Number(line.cost.totalAmount.amount))
        return sum + amount
      }, 0) || 0

    setTotalPrice(totalPrice)
    const totalQuantity = product.lines?.reduce((sum, line) => sum + line.quantity, 0) || 0
    setTotalQuantity(totalQuantity)

    setSelection && setSelection(parsedSelection)
  }, [product])

  const hasSizes = !!sizes && !!sizesAvailable && !!selection
  const hasVariants: boolean = (product?.variants?.length || 0) > 0
  if (!product?.lines?.length || !hasSizes || !hasVariants) {
    return null
  }

  return (
    <>
      <li className="relative mx-0 my-sm flex h-[130px] w-full last:mb-0">
        <Button
          styleType="base"
          to={product.link}
          className="focus shrink"
        >
          <Image
            objectFit="contain"
            src={product.thumbnail}
            alt={product?.title}
            sizes={`(min-width: ${viewports.md}px) 100px, 200px`}
            className="h-[130px] w-[90px]"
          />
        </Button>
        <Button
          styleType="icon"
          icon="trash"
          onClick={(): void => {
            if (lines) {
              const linesToRemove = lines
                .map((line) => {
                  const cartLine = product.lines?.find((cartLine) => cartLine.merchandise.id === line.merchandiseId)
                  return cartLine
                    ? {
                        id: cartLine.id,
                        ...line,
                        quantity: 0,
                      }
                    : null
                })
                .filter(filterNull)
              onChange(linesToRemove)
            }
          }}
          className="absolute left-0 top-0 flex justify-center self-center"
        >
          {t('button.remove')}
        </Button>
        <div className="relative flex grow flex-col justify-between pl-sm">
          <Button
            styleType="base"
            to={product.link}
            className="focus w-full flex-row justify-between text-xl font-bold"
          >
            {product.title} ({totalQuantity})
            <Price
              className="text-xl"
              currencyCode={currency}
              price={totalPrice}
            />
          </Button>
          <SizeSelectorBulk
            sizes={sizes}
            sizesAvailable={sizesAvailable}
            sizesSelected={selection}
            onChange={async (selection): Promise<void> => {
              setSelection && setSelection(selection)

              const processedSelection = processSelection(product.variants || null, selection)?.map((line) => {
                const p = product?.lines?.find((cartLine: CartLine) => cartLine.merchandise.id === line.merchandiseId)

                const currentQuantity = p?.quantity || 0

                return {
                  merchandiseId: line.merchandiseId,
                  id: p?.id as string,
                  quantity: currentQuantity > 0 && line.quantity === 0 ? 0 : line.quantity,
                }
              })

              processedSelection && onChange(processedSelection)
            }}
          />
        </div>
      </li>
      {hasRuler && <hr />}
    </>
  )
}
