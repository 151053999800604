'use client'
import { useEffect } from 'react'

import useUIState from 'core/hooks/useUIState'

export default function useDisableBodyScroll(disable: boolean): void {
  const { disableScroll, enableScroll } = useUIState()

  useEffect(() => {
    disable && disableScroll()

    return (): void => {
      disable && enableScroll()
    }
  }, [disable])
}
