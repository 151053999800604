import { memo } from 'react'

import useI18n from 'core/hooks/useI18n'
import { Locale } from 'core/i18n'
import i18n from 'core/i18n'
import { mapValues } from 'core/utils/language'

import DropDown from 'components/DropDown'

function LanguageSelect({ className }: { className?: string }): JSX.Element {
  const { select, locale } = useI18n()

  const labels = mapValues(i18n, (l) => ({
    label: l.label,
    value: l.code,
  })).filter((l) => l.value !== 'default')

  return (
    <DropDown
      className={className}
      options={labels}
      selected={{ label: locale.label, value: locale.code }}
      onSelect={(option) => select(option as Locale)}
      icon="world"
    />
  )
}

export default memo(LanguageSelect)
