import { useEffect, useState } from 'react'

import useOnWindowResize from './useOnWindowResize'

type windowSizeType = { w: number; h: number }
export default function useWindowSize(): windowSizeType {
  function getSize(): { w: number; h: number } {
    return {
      w: window.innerWidth || document.documentElement.clientWidth,
      h: window.innerHeight || document.documentElement.clientHeight,
    }
  }

  const [size, setSize] = useState<{ w: number; h: number }>({ w: 0, h: 0 })
  useOnWindowResize(() => setSize(getSize()))

  useEffect(() => {
    setSize(getSize())
  }, [])

  return size
}
