import { CartLine } from '@shopify/hydrogen-react/storefront-api-types'

import useI18n from 'core/hooks/useI18n'
import filterNull from 'core/utils/filterNull'
import { getLinkFromProduct } from 'core/utils/product'
import { cartTracking } from 'core/utils/tracking'

import viewports from 'components/AdamTheme/theme/viewports'
import Button from 'components/Button'
import Image from 'components/Image'
import Price from 'components/Price'

import { LineType } from './types'

export default function CartItem({
  line,
  onChange,
  disabled,
  hasRuler,
}: {
  line: CartLine
  onChange: (lines: LineType[]) => void
  disabled?: boolean
  hasRuler?: boolean
}): JSX.Element | null {
  const { t } = useI18n()
  const { product, title: size, id } = line.merchandise
  const availableQuantity =
    (line.merchandise.storeAvailability?.nodes.find(Boolean)?.quantityAvailable || Number.POSITIVE_INFINITY) -
    line.quantity

  const price = line.cost.totalAmount
  const collections = product.collections.nodes as unknown as { handle: string; isCategory: { value: boolean } }[]

  const category = collections.find((collection) => collection.isCategory?.value)?.handle

  const thumbnail =
    product.featuredImage?.url || product.images?.nodes.find(Boolean)?.url || line.merchandise.image?.url
  const link = getLinkFromProduct(
    product as unknown as {
      handle: string
      collections: {
        nodes?:
          | {
              id: string
              handle: string
              isCategory: {
                value: string
              } | null
            }[]
          | null
      }
    },
    ''
  )

  const totalDiscount = line.discountAllocations?.reduce((sum, discount) => {
    sum += Number(discount.discountedAmount.amount)
    return sum
  }, 0)
  const undiscountedPrice = totalDiscount
    ? Number(price.amount) + totalDiscount
    : Number(line.cost.compareAtAmountPerQuantity?.amount) * line.quantity
  const discounts = line.discountAllocations
    ?.map((discount) => ('title' in discount ? discount.title : null))
    .filter(filterNull)

  return (
    <>
      <li
        className="relative my-sm flex h-[100px] w-full justify-start last:mb-0"
        key={id}
      >
        <Button
          to={link}
          styleType="base"
          className="focus"
        >
          <Image
            className="flex aspect-[4/6] h-[100px] w-auto shrink"
            objectFit="contain"
            src={thumbnail as string}
            alt={product?.title}
            sizes={`(min-width: ${viewports.sm}px) 100px, 200px`}
          />
        </Button>

        <div className="relative flex grow flex-col justify-between pl-sm">
          <Button
            styleType="base"
            to={link}
            className="focus w-full flex-row justify-between pr-sm text-xl font-bold"
          >
            {product.title}
            <Price
              price={Number(price.amount)}
              undiscountedPrice={undiscountedPrice}
              currencyCode={price.currencyCode}
              className="text-xl"
            />
          </Button>

          <div className="flex grow justify-between pr-sm">
            {size && <span className="truncate">{`${t('cart.size')}: ${size}`}</span>}
            <div>
              {discounts.map((discount, index) => (
                <span
                  className="text-sm font-bold"
                  key={index}
                >
                  {discount}
                </span>
              ))}
            </div>
          </div>
          <div className="flex content-center items-center justify-end text-xl font-bold">
            <Button
              className="mr-md"
              styleType="icon"
              icon={line.quantity === 1 ? 'trash' : 'minus'}
              disabled={disabled}
              onClick={(): void => {
                onChange([
                  {
                    id: line.id,
                    quantity: line.quantity - 1,
                  },
                ])

                cartTracking({
                  type: 'remove',
                  products: [
                    {
                      name: product.title,
                      id: line.merchandise.sku as string,
                      variant: line.merchandise.title,
                      category: category as string,
                      price: price.amount,
                      quantity: 1,
                    },
                  ],
                  currencyCode: price.currencyCode,
                })
              }}
            >
              Minus
            </Button>
            {line.quantity}
            <Button
              className="ml-md"
              styleType="icon"
              icon="plus"
              disabled={availableQuantity <= 0}
              onClick={(): void => {
                onChange([
                  {
                    id: line.id,
                    quantity: line.quantity + 1,
                  },
                ])

                cartTracking({
                  type: 'add',
                  products: [
                    {
                      name: product.title,
                      id: line.merchandise.sku as string,
                      variant: line.merchandise.title,
                      category: category as string,
                      price: price.amount,
                      quantity: 1,
                    },
                  ],
                  currencyCode: price.currencyCode,
                })
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </li>
      {hasRuler && <hr />}
    </>
  )
}
