import FormikErrorMessage from 'components/FormikErrorMessage'

export default function GeneralFormError(): JSX.Element {
  return (
    <div className="mb-md text-right font-bold">
      <FormikErrorMessage
        touched
        name="form"
      />
    </div>
  )
}
