import { initGraphQLTada } from 'gql.tada'

import type { introspection } from 'core/schema/graphql-shopify-customer.d.ts'

import getClient from './getClient'

export default function customer(accessToken: string): ReturnType<typeof getClient> {
  const url = `https://shopify.com/${process.env.SHOPIFY_SHOP_ID}/account/customer/api/unstable/graphql`

  return getClient(url, { Authorization: accessToken })
}

export const graphql = initGraphQLTada<{
  introspection: introspection
}>()
