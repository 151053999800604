import { useEffect } from 'react'

const hidePosition = 'translateX(100px)'

export default function useHideIntercom(hide: boolean): void {
  useEffect(() => {
    const intercomEl = document.querySelector('#intercom-container') as unknown as HTMLElement
    if (intercomEl) {
      intercomEl.style.transform = hide ? hidePosition : ''
    }

    return () => {
      try {
        const intercomEl = document.querySelector('#intercom-container') as unknown as HTMLElement
        if (intercomEl) {
          intercomEl.style.transform = ''
        }
      } catch (e) {}
    }
  }, [hide])
}
