import useI18n from 'core/hooks/useI18n'
import useOverlayControls from 'core/hooks/useOverlayControls'
import { AnalyticsCategory } from 'core/types/tracking'
import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'

export default function CloseBar({
  align = 'right',
  trackingCategory,
  children,
}: {
  align?: 'left' | 'right'
  trackingCategory?: keyof typeof AnalyticsCategory
  children?: React.ReactNode
}): JSX.Element {
  const { t } = useI18n()
  const { hide } = useOverlayControls()

  return (
    <div
      className={mergeClasses(
        'bg-cotton h-cart-bar flex justify-between content-center items-center px-md',
        align === 'left' ? 'flex-row' : 'flex-row-reverse'
      )}
    >
      <Button
        styleType="icon"
        icon="cross"
        tracking={
          trackingCategory
            ? {
                category: trackingCategory,
                label: 'close',
              }
            : null
        }
        onClick={hide}
      >
        {t('button.close')}
      </Button>
      {children}
    </div>
  )
}
