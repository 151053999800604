const keys = <T extends Record<string, any>, K extends keyof T>(obj: T): K[] => {
  const keys: K[] = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      keys.push(key as unknown as K)
    }
  }

  return keys as K[]
}

export const mapValues = <T extends { [index: string]: any }, K extends keyof T>(
  obj: T,
  fn: (value: T[K], i: number) => void
): any[] => {
  const k = keys(obj) as K[]
  return k.map((key, i) => fn(obj[key], i))
}

export const chunk: <T>(arr: T[], len: number) => T[][] = (arr, len) => {
  const chunks: (typeof arr)[] = []
  let i = 0
  const n = arr?.length || 0

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}
