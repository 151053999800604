import { CartLine } from '@shopify/hydrogen-react/storefront-api-types'

import useUIState from 'core/hooks/useUIState'
import mergeClasses from 'core/utils/mergeClasses'

import CartItem from 'components/CartItem'
import Scroll from 'components/Scroll'

import { LineType } from '../CartItem/types'

export default function LineList<TObj>({
  lines,
  onChange,
  noScroll,
  className,
}: {
  lines: CartLine[]
  onChange: (lines: LineType[]) => Promise<void>
  noScroll?: boolean
  className?: string
} & TObj): JSX.Element {
  const { processing } = useUIState()

  const content = (
    <ol
      className={mergeClasses(
        'flex flex-col grow w-full',
        processing.checkout && 'pointer-events-none opacity-50',
        className
      )}
    >
      {lines.map((line, index) => (
        <CartItem
          key={line?.id}
          line={line}
          onChange={onChange}
          hasRuler={index < lines.length - 1}
        />
      ))}
    </ol>
  )

  return noScroll ? content : <Scroll className="px-lg py-md">{content}</Scroll>
}
