import { useRouter } from 'next/navigation'
import { useState } from 'react'

import { useCart } from 'core/context/ShopifyProvider'
import useI18n from 'core/hooks/useI18n'
import useIsTouchDevice from 'core/hooks/useIsTouchDevice'
import useIsWholesale from 'core/hooks/useIsWholesale'
import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import useUIState from 'core/hooks/useUIState'
import session from 'core/session'

import type { TrendingResponseType } from 'app/api/product/trending/route'

import Button from 'components/Button'
import CartSummary from 'components/CartSummary'
import ProductCard from 'components/ProductCard'
import PulseLoader from 'components/PulseLoader'
import Scroll from 'components/Scroll'

import CloseBar from '../Common/CloseBar'

const EmptyCheckout = (): JSX.Element => {
  const { t } = useI18n()
  const { channel } = useSession()
  const isTouchDevice = useIsTouchDevice()
  const { data } = useSWR<TrendingResponseType>(`/api/product/trending?channel=${channel}`)

  return (
    <div className="absolute inset-x-0 top-0 m-auto flex h-full flex-col justify-between">
      <CloseBar align="left" />
      <Scroll>
        <div className="flex  flex-col p-md">
          <h4 className="w-full bg-pillow p-2xl text-center">Empty cart</h4>
          <h4 className="">{t('plp.trending')}</h4>
          <div className="flex columns-2 flex-wrap gap-md [&>*]:w-[calc(50%-theme(spacing.sm))]">
            {data?.hits.map((product, index) => (
              <ProductCard
                key={index}
                channel={channel}
                product={product}
                url={product.link}
                position={index + 1}
                touchDevice={isTouchDevice}
                direct
                smallControls
              />
            ))}
          </div>
        </div>
      </Scroll>
    </div>
  )
}

export default function Cart(): JSX.Element {
  const [isClicked, setIsClicked] = useState(false)
  const { push } = useRouter()
  const { t } = useI18n()
  const { processing } = useUIState()
  const isWholesale = useIsWholesale()
  const { storefrontToken } = useSession()

  const { lines, checkoutUrl } = useCart()
  const loading = status === 'fetching'

  const linesCount = lines?.length || 0
  const hasLines = linesCount > 0
  const productCount = lines?.reduce((sum, line) => {
    sum += line?.quantity || 0
    return sum
  }, 0)

  const combinedLoading = [loading].some(Boolean)
  const url = storefrontToken ? `${checkoutUrl}&logged_in=true` : checkoutUrl

  if (combinedLoading) {
    return (
      <div className="absolute inset-x-0 top-0 m-auto flex h-full flex-col justify-between">
        <CloseBar align="left" />
        <PulseLoader color="ink" />
      </div>
    )
  }

  if (hasLines) {
    return (
      <div className="absolute inset-x-0 top-0 m-auto flex h-full flex-col justify-between pb-lg">
        <CloseBar align="left" />
        <CartSummary />

        {isWholesale && (
          <div className="m-md bg-ocean p-md font-bold text-ghost">
            You only have one chance to click checkout, you cannot currently edit your cart afterwards.
          </div>
        )}

        <div className="mx-md">
          <Button
            color="grass"
            onClick={async (): Promise<void> => {
              if (isWholesale) {
                session.cartId = ''
              }
              setIsClicked(true)
              push(url as string)
            }}
            disabled={processing.checkout}
            loading={isClicked}
            tracking={{
              category: 'cart',
              label: 'checkout',
            }}
            className="w-full"
          >
            {t('cart.checkout')}
            {hasLines && ` (${productCount})`}
          </Button>
        </div>
      </div>
    )
  }

  return <EmptyCheckout />
}
