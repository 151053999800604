import useAccount from 'core/hooks/useAccount'
import useSession from 'core/hooks/useSession'
import { identify, notify, TrackType } from 'core/utils/klaviyo'
import stringToBoolean from 'core/utils/stringToBoolean'

export default function useKlaviyo(): typeof klaviyoObject {
  const isB2B = stringToBoolean(process.env.NEXT_PUBLIC_B2B)
  const { user } = useAccount()
  const { email: newsletterEmail } = useSession()

  const customer = {
    $email: user?.email || newsletterEmail,
  }
  const valid = !!customer?.$email && !isB2B

  const klaviyoObject = {
    identify: () => valid && identify(customer),
    track: (type: TrackType, properties: Record<string, string | number>) =>
      valid && notify(customer, type, properties),
  }

  return klaviyoObject
}
