'use client'

import { animated, useTransition } from '@react-spring/web'
import FocusTrap from 'focus-trap-react'
import { usePathname } from 'next/navigation'
import { useContext, useState, useEffect, useRef, HTMLAttributes } from 'react'

import { OverlayContext, OverlayPositions } from 'core/context/OverlayProvider'
import useDisableBodyScroll from 'core/hooks/useDisableBodyScroll'
import useHideIntercom from 'core/hooks/useHideIntercom'
import useOnDocumentKey from 'core/hooks/useOnDocumentKey'
import useOverlayControls from 'core/hooks/useOverlayControls'
import mergeClasses from 'core/utils/mergeClasses'

import transitions from 'components/AdamTheme/theme/transitions'

import Content from './Content'

export function Backdrop({
  position,
  zIndex,
  style,
  ...props
}: {
  position: 'right' | 'left' | 'bottom' | 'center'
  zIndex?: number
} & HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <animated.div
      className={mergeClasses(
        'fixed pointer-events-auto cursor-pointer inset-0 z-overlay-manager',
        {
          right: 'bg-gradient-to-r from-ink/[0.6] to-ink/[0.9] to-90%',
          left: 'bg-gradient-to-l from-ink/[0.6] to-ink/[0.9] to-90%',
          bottom: 'bg-gradient-to-b from-ink/[0.6] to-ink/[0.9] to-90%',
          center: 'bg-gradient-to-b from-ink/[0.6] to-ink/[0.6] to-90%',
        }[position]
      )}
      style={{ ...style, zIndex }}
      {...props}
    />
  )
}

const getTransition = (
  theme: keyof typeof OverlayPositions | null
): {
  leave: { transform: string }
  enter: { transform: string }
  from: { transform: string }
} => {
  switch (theme) {
    case OverlayPositions.bottom:
      return {
        leave: {
          transform: `scale(1) rotateY(0) translate(-50%, 100%)`,
        },
        enter: {
          transform: `scale(1) rotateY(0) translate(-50%, 0%)`,
        },
        from: {
          transform: `scale(1) rotateY(0) translate(-50%, 100%)`,
        },
      }
    case OverlayPositions.center:
      return {
        leave: {
          transform: `scale(1) rotateY(0) translate(-50%, 150%)`,
        },
        enter: {
          transform: `scale(1) rotateY(0) translate(-50%, -50%)`,
        },
        from: {
          transform: `scale(1) rotateY(0) translate(-50%, 150%)`,
        },
      }
    case OverlayPositions.left:
      return {
        leave: {
          transform: `scale(1) rotateX(0) translate(-100%, 0%)`,
        },
        enter: {
          transform: `scale(1) rotateX(0) translate(0%, 0%)`,
        },
        from: {
          transform: `scale(1) rotateX(0) translate(-100%, 0%)`,
        },
      }
    case OverlayPositions.right:
    default:
      return {
        leave: {
          transform: `scale(1) rotateX(0) translate(100%, 0%)`,
        },
        enter: {
          transform: `scale(1) rotateX(0) translate(0%, 0%)`,
        },
        from: {
          transform: `scale(1) rotateX(0) translate(100%, 0%)`,
        },
      }
  }
}

export default function OverlayManager(): JSX.Element {
  const pathname = usePathname()
  const prevLocation = useRef<string>()

  const overlayContext = useContext(OverlayContext)
  const { type } = overlayContext

  const { hide } = useOverlayControls()

  const [trap, setTrap] = useState(false)

  useHideIntercom(!!type)
  useDisableBodyScroll(!!type)
  useOnDocumentKey('Escape', hide, {
    active: !!type,
    scope: [type],
  })

  // close overlay on route change
  useEffect(() => {
    if (prevLocation?.current !== pathname) {
      hide()
      prevLocation.current = pathname as string
    }
  }, [pathname])

  const backdropTransition = useTransition(overlayContext, {
    position: 'absolute',
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      pointerEvents: 'none',
    },
    config: {
      duration: 350,
    },
  })

  const contentTransition = useTransition(overlayContext, {
    leave: ({ options }) => getTransition(options.position).leave,
    enter: ({ options }) => getTransition(options.position).enter,
    from: ({ options }) => getTransition(options.position).from,
    onRest: () => setTrap(!!type),
    config: ({ options }) => ({ ...transitions.springMenu, mass: options.position === 'bottom' ? 1.2 : 1 }),
  })

  return (
    <>
      {backdropTransition(
        (style, { type, options }) =>
          type && (
            <Backdrop
              position={options.position}
              style={style as unknown as React.CSSProperties}
              onClick={hide}
            />
          )
      )}
      <FocusTrap
        active={trap}
        focusTrapOptions={{
          allowOutsideClick: true,
          initialFocus: false,
          escapeDeactivates: false,
        }}
      >
        <div>
          {contentTransition((style, { type, options }) => {
            return (
              type && (
                <Content
                  type={type}
                  options={options}
                  styles={style}
                />
              )
            )
          })}
        </div>
      </FocusTrap>
    </>
  )
}
