import useI18n from 'core/hooks/useI18n'
import mergeClasses from 'core/utils/mergeClasses'

type LabelProps = {
  htmlFor: string
  isRequired?: boolean
  isOptional?: boolean
  active?: boolean
  children: string
  className?: string
}

export default function Label({
  htmlFor,
  isRequired,
  isOptional,
  active,
  children,
  className,
}: LabelProps): JSX.Element {
  const { t } = useI18n()

  return (
    <label
      htmlFor={htmlFor}
      className={mergeClasses(
        'absolute bottom-sm left-xs m-auto transition pointer-events-none',
        'transition-all duration-150 ease-in-out-cubic',
        active ? 'text-sm text-unfocus -translate-y-[20px]' : 'text-xl text-ink',
        className
      )}
    >
      {children}
      {isRequired && ' *'}
      {isOptional && ` (${t('checkout.input.optional-field.label')})`}
    </label>
  )
}
