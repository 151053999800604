import mergeClasses from 'core/utils/mergeClasses'

import Price from 'components/Price'

export default function FreeShipping({
  amountTillFreeShipping,
  currency = 'EUR',
  percentileOfFreeShipping,
  contrast,
}: {
  amountTillFreeShipping: number
  currency?: string
  percentileOfFreeShipping: number
  contrast?: boolean
}): JSX.Element {
  return (
    <div
      className={mergeClasses(
        'text-sm flex items-center justify-center mt-md mb-sm relative py-sm px-0 rounded-sm overflow-hidden z-1',
        `before:content-[''] before:absolute before:block before:left-0 before:top-0 before:h-full before:w-full before:-z-1`,
        contrast ? 'before:bg-ghost' : 'before:bg-pillow',
        `after:content-[''] after:absolute after:block after:left-0 after:top-0 after:h-full after:bg-sky after:w-[calc(var(--percentile)*100%)] after:-z-1`
      )}
      style={
        {
          '--percentile': percentileOfFreeShipping,
        } as React.CSSProperties
      }
    >
      {`Spend `}
      <Price
        className="mx-xs text-sm"
        currencyCode={currency}
        price={amountTillFreeShipping}
      />
      {` more for free shipping`}
    </div>
  )
}
